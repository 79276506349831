<template>
  <b-sidebar
    id="wallet-form"
    ref="sidebar"
    width="50%"
    :title="requestType === 'DEPOSIT' ? 'Request Deposit' : 'Request Credit'"
    bg-variant="white"
    right
    shadow
    backdrop
    no-header
    @hidden="resetData()"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ requestType === 'DEPOSIT' ? 'Request Deposit' : 'Request Credit' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card
        no-body
        class="p-1"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
            sm="12"
          >
            <b-form-group>
              <vue-autosuggest
                v-model="companyName"
                :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search company..'}"
                :suggestions="[{ data: companyList }]"
                :limit="10"
                @focus="debounceSearch(0, getCompanyList)"
                @input="debounceSearch(0, getCompanyList)"
                @selected="getSelectedCompany"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.company_name }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="6"
            sm="12"
          >
            <b-form-group
              label="Amount"
              label-for="amount"
            >
              <b-input-group prepend="BDT">
                <b-form-input
                  id="total_amount"
                  v-model="requestForm.amount"
                  trim
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="requestType === 'DEPOSIT'"
            cols="6"
            md="6"
            sm="12"
          >
            <b-form-group
              label="Proof of Payment"
              label-for="payment-slip"
            >
              <b-form-file
                id="payment-slip"
                ref="file-input"
                v-model="paymentSlip"
                class="mb-2"
              /></b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
            sm="12"
          >
            <b-form-group
              label-for="note"
              label="Note"
            >
              <div class="form-label-group">
                <b-form-textarea
                  id="note"
                  v-model="requestForm.note"
                  rows="1"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
            sm="12"
          >
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                type="submit"
                @click="submitWalletRequest()"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                Send
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </b-sidebar>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'WalletDepositForm',
  components: {
    VueAutosuggest,
  },
  mixins: [helper],
  props: {
    requestType: {
      type: String,
    },
  },
  data() {
    return {
      companyList: [],
      companyName: '',
      paymentSlip: null,
      isLoading: false,
      requestForm: {
        transaction_type: '',
        amount: '',
        note: '',
        company_id: '',
      },
    }
  },
  methods: {
    getCompanyList() {
      api.getData(`b2e_dashboard/company_list/?company_name=${this.companyName}`, true).then(response => {
        if (response.data.status) {
          this.companyList = response.data.result.results
        }
      }).catch()
    },
    getSelectedCompany(item) {
      this.requestForm.company_id = item.item.company_id
      this.companyName = item.item.company_name
    },
    submitWalletRequest() {
      this.isLoading = true
      this.requestForm.transaction_type = this.requestType
      if (this.requestType === 'DEPOSIT' && this.paymentSlip) {
        const formData = new FormData()
        Object.keys(this.requestForm).forEach(key => {
          formData.append(key, this.requestForm[key])
        })
        formData.append('proof_of_payment', this.paymentSlip)
        this.callApi(formData)
      } else {
        this.callApi(this.requestForm)
      }
    },
    callApi(data) {
      api.postData('b2e_dashboard/wallet_transaction/create/', true, data)
        .then(response => {
          if (response.data.status) {
            this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been processed successfully')
            this.$emit('getWalletList')
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
        }).finally(() => {
          this.isLoading = false
        })
    },
    resetData() {
      this.requestForm = {
        transaction_type: '',
        amount: '',
        note: '',
        company_id: '',
      }
      this.paymentSlip = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
